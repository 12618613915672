export const palette = {
    primary: {
        main: '#C80000', // primary idle
        hover: '#910F05',
        active: '#640A00',
    },
    brand: {
        main: '#E60000', // primary brand
    },
    secondary: {
        main: '#0D0D0D', // secondary idle
        hover: '#404040',
        active: '#666666',
    },
    secondaryAlt: {
        idle: '#FFFFFF',
        hover: '#F6F6F4',
        active: '#E5E5E5',
    },
    dark: {
        intense: '#0D0D0D',
        coloured: '#262626',
        simple: '#404040',
        subdued: '#666666',
        lighter: '#808080',
        darkTransparent: 'rgba(0, 0, 0, 0.2)',
        decent: '#E5E5E5',
    },
    light: {
        white: '#FFFFFF',
        lightShade: '#F6F6F4',
        lightWarm: '#FCFAF5',
        lightShade40: '#FCFCFB',
    },
    onColouredSurface: {
        hover: '#4040401A', // 10% opacity
        press: '#40404033', // 10% opacity
    },
    error: {
        main: '#C80000',
        light: '#FBEBEB',
    },
    success: {
        main: '#477306',
        light: '#E7EDD4',
    },
    warning: {
        main: '#BE4B0A',
        light: '#FFF8E8',
    },
    information: {
        main: '#0F558C',
        light: '#E8ECF4',
    },
    brown: {
        main: '#4B2E23',
        light: '#EFE8DF',
    },
    gold: {
        main: '#E6B96E',
        light: '#F9EEDC',
    },
    special: {
        green1: '#459E31',
        green2: '#7CBB6E',
        green3: '#B5D8AD',
        yellow1: '#F4C832',
        yellow2: '#F5D66D',
        yellow3: '#FBE9AD',
    },
}

export type EVPalette = typeof palette
